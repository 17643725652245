import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"
import { Icon } from "@iconify/vue"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./assets/css/site.css"

const pinia = createPinia()

const app = createApp(App)
app.use(pinia)
app.use(router)

// eslint-disable-next-line vue/multi-word-component-names
app.component("Icon", Icon)

app.mount("#app")
