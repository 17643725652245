import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import HomeView from "./views/HomeView.vue"
import { useAuthStore } from "./stores/auth"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "./views/LoginView.vue"),
    meta: {
      allowAnnonymous: true
    }
  },
  {
    path: "/",
    name: "Home",
    component: HomeView
  },
  {
    path: "/stores",
    name: "Stores",
    component: () => import("./views/StoresView.vue"),
    meta: {
      roles: ["Admin"]
    }
  },
  {
    path: "/stores/:storeId",
    name: "AddEditStore",
    component: () => import("./views/AddEditStoreView.vue"),
    meta: {
      roles: ["Admin"]
    }
  },
  {
    path: "/stores/:storeId/DeviceSettings",
    name: "DeviceSettings",
    component: () => import("./views/DeviceSettingsView.vue"),
    meta: {
      roles: ["Admin"]
    }
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("./views/UsersView.vue"),
    meta: {
      roles: ["Admin", "Manager"]
    }
  },
  {
    path: "/users/:userId",
    name: "AddEditUser",
    component: () => import("./views/AddEditUserView.vue"),
    meta: {
      roles: ["Admin", "Manager"]
    }
  },
  {
    path: "/drivers",
    name: "Drivers",
    component: () => import("./views/DriversView.vue"),
    meta: {
      roles: ["Admin", "Manager"]
    }
  },
  {
    path: "/drivers/:driverId/location",
    name: "DriverLocationInfo",
    component: () => import("./views/DriverLocationInfo.vue"),
    meta: {
      roles: ["Admin", "Manager"]
    }
  },
  {
    path: "/deliveries",
    name: "Deliveries",
    component: () => import("./views/DeliveriesView.vue")
  },
  {
    path: "/deliveries/:deliveryId",
    name: "DeliveryInfo",
    component: () => import("./views/DeliveryInfoView.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.every((route) => route.meta.allowAnnonymous)) {
    next()
    return
  }
  const authStore = useAuthStore()
  if (!authStore.isAuthenticated()) {
    next({ name: "Login", query: { redirect: window.location.pathname } })
  } else {
    const authorized = to.matched.every((route) => {
      const roles = route.meta.roles as []
      if (!roles || roles.length == 0) return true
      return roles.some((r) => r == authStore.userInfo?.role)
    })
    if (authorized) next()
    else next({ name: "Home" })
  }
})

export default router
