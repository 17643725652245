import { fetchData, patchData, postData } from "."

const MODULE_PATH = "/messages"

export default {
  async getMessageCounts(storeId?: number): Promise<MessageCount[]> {
    let url = `${MODULE_PATH}`
    if (storeId) url += `?StoreId=${storeId}`
    const data = await fetchData<MessageCount[]>(url)
    data.forEach((x) => {
      if (x.lastMessageTime) x.lastMessageTime = new Date(x.lastMessageTime + "+00:00")
    })
    return data
  },
  async getMessages(driverId: number): Promise<Message[]> {
    const url = `${MODULE_PATH}/${driverId}`
    const data = await fetchData<Message[]>(url)
    data.forEach((x) => {
      if (x.sentTime) x.sentTime = new Date(x.sentTime + "+00:00")
    })
    return data
  },
  setAsRead(messageId: string): Promise<void> {
    const url = `${MODULE_PATH}/${messageId}/Read`
    return patchData(url, {})
  },
  sendMessage(driverId: number, msg: string): Promise<string> {
    const url = `${MODULE_PATH}/${driverId}`
    return postData(url, { messageText: msg })
  }
}

export interface MessageCount {
  driverId: number
  driverName: string
  unreadMessages: number
  lastMessageText: string
  lastMessageTime: Date | null
}

export interface Message {
  messageId: string
  messageText?: string
  sentTime: Date
  fromDriver: boolean
  hasAttachment: boolean
  userName?: string
  isDelivered: boolean
  isRead: boolean
}
