<template>
  <div class="driver-row" :class="{ unread: count.unreadMessages > 0 }" @click="emit('selected')">
    <div class="avatar">
      <Icon :icon="'ic:person'" class="w-100 h-100" />
    </div>
    <div class="info">
      <div class="d-flex justify-content-between">
        <span class="driver-name">{{ count.driverName }}</span>
        <small>{{ timeString }}</small>
      </div>
      <small class="last-message">{{ count.lastMessageText }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MessageCount } from "@/api/messages"
import { computed } from "vue"

const props = defineProps<{
  count: MessageCount
}>()

const timeString = computed(() => {
  const time = props.count.lastMessageTime
  if (!time) return ""
  const now = new Date()
  const time1 = time.getTime()
  const time2 = now.getTime()
  const msPerDay = 86400000
  const dateOnly1 = time1 - (time1 % msPerDay)
  const dateOnly2 = time2 - (time2 % msPerDay)
  if (dateOnly1 == dateOnly2) return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  else if (dateOnly2 - dateOnly1 == msPerDay) return "Yesterday"
  else return time.toLocaleDateString([], { month: "short", day: "numeric" })
})

const emit = defineEmits<{ (e: "selected"): void }>()
</script>

<style scoped>
.driver-row {
  background-color: white;
  border-bottom: 1px solid #ddd;
  display: flex;
  padding: 0.5rem;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}
.driver-row:hover {
  background-color: #eee;
}
.driver-row .avatar {
  height: 35px;
  width: 35px;
  min-width: 35px;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: 2px;
  background-color: lightgray;
  border-radius: 100%;
}
.driver-row .info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 0;
}

.driver-row.unread .driver-name {
  font-weight: bold;
}

.driver-row .last-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  margin-left: 3px;
}
</style>
