<template>
  <div class="message-row" :class="{ incoming: props.message.fromDriver }">
    <span v-if="!props.message.fromDriver" class="text-secondary me-3 text-end" style="font-size: 12px">
      {{ props.message.userName }}
    </span>
    <div class="message-bubble">
      <span :title="props.message.sentTime.toString()">
        {{ props.message.messageText }}
      </span>
    </div>
    <div class="message-status">
      <small v-if="message.isRead">Read</small>
      <small v-else-if="message.isDelivered">Delivered</small>
      <div v-else-if="message.messageId?.startsWith('tmp')" class="spinner-border spinner-border-sm mt-1" />
      <small v-else>Sent</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Message } from "@/api/messages"

const props = defineProps<{
  message: Message
}>()
</script>

<style scoped>
.message-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.message-row:not(.incoming) {
  padding-left: 3rem;
  padding-right: 0.5rem;
}
.message-row.incoming {
  padding-left: 0.5rem;
  padding-right: 3rem;
}
.message-bubble {
  display: flex;
  justify-content: end;
}
.message-row.incoming .message-bubble {
  justify-content: start;
}
.message-row.incoming .message-status {
  display: none;
}
.message-status {
  min-width: 3rem;
  display: flex;
  padding-right: 10px;
  padding-bottom: 5px;
  align-items: end;
  justify-content: end;
  font-size: small;
}
.message-bubble > span {
  border-radius: 12px;
  padding: 8px 16px;
  width: auto;
  cursor: default;
}
.message-row.incoming .message-bubble > span {
  background-color: gray;
  color: white;
}
.message-row:not(.incoming) .message-bubble > span {
  background-color: rgb(64, 152, 224);
  color: white;
}
</style>
