import { defineStore } from "pinia"
import router from "../router"
import { decodeJwt } from "jose"

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      access_token: null as string | null,
      access_token_exp: 0 as number,
      userInfo: null as AuthUserInfo | null
    }
  },
  getters: {
    storeSelectRequired(state) {
      return !state.userInfo?.storeId
    }
  },
  actions: {
    isAuthenticated(): boolean {
      return !!this.getAccessToken()
    },
    getAccessToken(): string | null {
      if (!this.access_token) {
        const token = localStorage.getItem("auth")
        if (!token) return null
        this.setToken(token, true)
      }
      if (tokenExpired(this.access_token_exp)) return null
      return this.access_token
    },
    async setToken(token: string | null, fromLocalStorage = false) {
      this.access_token = token
      if (token) {
        const jwt = (<unknown>decodeJwt(token)) as Token
        this.access_token_exp = jwt.exp || 0
        if (!fromLocalStorage) localStorage.setItem("auth", token)
        else if (tokenExpired(this.access_token_exp)) {
          console.log("token epired")
          return
        }
        this.userInfo = {
          name: jwt.unique_name,
          role: jwt.role,
          storeId: jwt.store_id
        }
      } else {
        this.access_token_exp = 0
        this.userInfo = null
        if (!fromLocalStorage) localStorage.removeItem("auth")
      }
      if (this.isAuthenticated()) {
        let redirect = null
        if (router.currentRoute.value.name == "Login") {
          redirect = router.currentRoute.value.query.redirect?.length ? router.currentRoute.value.query.redirect.toString() : "/"
        }
        if (redirect) router.push({ path: redirect })
      }
    },
    async logout() {
      try {
        localStorage.removeItem("auth")
        this.access_token = null
      } catch (ex) {
        console.error("Error calling logout function: ", ex)
      }
      this.setToken(null)
      router.push({ name: "Login" })
    }
  }
})

function tokenExpired(exp: number) {
  const now = Date.now() / 1000 // Divide by 1000 to remove ms
  return now > exp
}

interface Token {
  unique_name: string
  role: Role
  store_id?: number
  exp: number
}

export interface AuthUserInfo {
  name: string
  role: Role
  storeId?: number
}

export enum Role {
  None = "",
  Admin = "Admin",
  Manager = "Manager",
  Driver = "Driver"
}
