import { useAuthStore } from "@/stores/auth"

export function fetchData<T>(url: string, authToken: string | null = null): Promise<T> {
  return fetchDataInternal<T>(url, "GET", null, authToken)
}

export async function fetchBlobData(url: string, authToken: string | null = null): Promise<Blob> {
  const resp = await fetchDataInternalRaw(url, "GET", null, authToken)
  return await resp.blob()
}

export function postData<TData, TResponse>(url: string, data: TData, authToken: string | null = null): Promise<TResponse> {
  const payload = JSON.stringify(data)
  return fetchDataInternal<TResponse>(url, "POST", payload, authToken)
}

export function patchData<TData, TResponse>(url: string, data: TData): Promise<TResponse> {
  const payload = JSON.stringify(data)
  return fetchDataInternal<TResponse>(url, "PATCH", payload)
}

export function deleteData(url: string): Promise<void> {
  return fetchDataInternal<void>(url, "DELETE")
}

async function fetchDataInternal<T>(url: string, method: string, body: string | null = null, authToken: string | null = null): Promise<T> {
  const resp = await fetchDataInternalRaw(url, method, body, authToken)
  let data = {}
  if (resp.status != 204) {
    data = await resp.json()
  }
  return data as T
}

async function fetchDataInternalRaw(url: string, method: string, body: string | null = null, authToken: string | null = null): Promise<Response> {
  let headers
  if (!authToken) {
    const authStore = useAuthStore()
    authToken = authStore.getAccessToken()
  }
  headers = {
    Authorization: "Bearer " + authToken
  }
  if (body) headers = { ...headers, "Content-Type": "application/json" }
  const resp = await fetch(`/api/web${url}`, {
    method,
    headers,
    body
  })
  if (resp.ok) return resp
  if (resp.status == 401) {
    const authStore = useAuthStore()
    authStore.logout()
    throw "Unauthorized"
  }
  const respText = await resp.text()
  let error: ApiError
  if (respText) {
    try {
      error = JSON.parse(respText) as ApiError
    } catch {
      error = { message: respText }
    }
  } else {
    error = { message: `Unknown server error. Response Code: ${resp.status} ${resp.statusText}` }
  }
  throw error
}

export interface ApiError {
  message: string
}
