<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Datascan Delivery</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul v-if="user" class="navbar-nav me-auto">
          <router-link v-for="route in routes" :key="route.to" v-slot="{ isActive, navigate }" :to="route.to" custom>
            <li class="nav-item" @click="navigate">
              <span class="nav-link" :class="{ active: isActive }">{{ route.title }}</span>
            </li>
          </router-link>
        </ul>
        <ul class="ms-auto navbar-nav navbar-end">
          <div v-if="user" class="d-flex">
            <li class="navbar-text me-2">Hi {{ user }}!</li>
            <li class="nav-link" @click="logout">Log out</li>
          </div>
          <router-link v-else to="Login" v-slot="{ navigate }" custom>
            <li class="nav-item" @click="navigate">
              <span class="nav-link">Log in</span>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
  <div class="pt-3 px-3 flex-fill" style="overflow-y: auto">
    <messaging-popup />
    <router-view class="h-100 d-flex flex-column" />
  </div>
  <hr class="m-0" />
  <footer class="text-center pb-3">
    <small>&copy; 2023 - Datascan Delivery Portal</small>
  </footer>
</template>

<script setup lang="ts">
import { computed, provide } from "vue"
import { useRoute, useRouter } from "vue-router"

const route = useRoute()
const router = useRouter()
import { Role, useAuthStore } from "./stores/auth"
import MessagingPopup from "./components/MessagePopup/MessagingPopup.vue"

const auth = useAuthStore()

const routes = computed(() => {
  const list = []
  if (currentUserRole.value == Role.Admin) {
    list.push({
      to: "/stores",
      title: "Stores"
    })
  }
  if (currentUserRole.value != Role.Driver) {
    list.push({
      to: "/users",
      title: "Users"
    })
    list.push({
      to: "/drivers",
      title: "Drivers"
    })
  }
  list.push({
    to: "/deliveries",
    title: "Deliveries"
  })
  return list
})

function logout() {
  auth.logout()
}

const currentUserRole = computed(() => auth.userInfo?.role ?? Role.None)
const user = computed(() => {
  const auth = useAuthStore()
  if (auth.isAuthenticated()) {
    return auth.userInfo?.name
  }
  return undefined
})

provide("setQuery", setQuery)

async function setQuery(key: string, value?: string) {
  const query = route.query
  return router.replace({ query: { ...query, [key]: value } })
}
</script>

<style>
html,
body {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav-link:hover {
  cursor: pointer;
}
</style>
